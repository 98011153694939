import React, { Component } from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

let mapkey = ''
if (process.env.GATSBY_MAPBOX_KEY) {
  mapkey = process.env.GATSBY_MAPBOX_KEY
}

const Map = ReactMapboxGl({
    accessToken: mapkey,
});

class MapBox extends Component {
  render() {
    return (
        <Map
            center={[-76.923398, 40.255949]}
            zoom={[16]}
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{ height: '50vh', width: '100%' }}>
            <Layer type="symbol" id="marker" layout={{ 'icon-image': 'doctor-15', 'icon-size': 2 }}>
                <Feature coordinates={[-76.923398, 40.255949]} />
            </Layer>
        </Map>
    )
  }
}

export default MapBox;